<template>
    <div>
        <v-card flat>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="3" md="2" v-for="(party, index) of parties" :key="index">
                        <v-card flat outlined>
                            <v-card-title>
                                <v-icon class="mr-2">{{ party.icon }}</v-icon> {{ party.name }}
                            </v-card-title>
                            <v-card-text>
                                <v-list>
                                    <v-list-item v-if="party.organisation">
                                    <v-list-item-title>{{ party.organisation.name }}</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="party.onBehalfOrganisation">
                                    <v-list-item-title>{{ party.onBehalfOrganisation.name }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
export default {
    props: ['item'],
    data: () => ({
        allParties: [
            { icon: 'account_balance', name: 'Bank', value: 'bank' },
            { icon: 'directions_boat_filled', name: 'Shipper', value: 'shipper' },
            { icon: 'fast_forward', name: 'Forwarder', value: 'forwarder' },
            { icon: 'call_received', name: 'Consignee', value: 'consignee' },
            { icon: 'shopping_cart', name: 'Buyer', value: 'buyer' },
            { icon: 'notifications', name: 'Notify Party', value: 'notifyParty' },
            { icon: 'local_post_office', name: 'Courier Party', value: 'courierParty' },
        ]
    }),
    computed: {
        parties() {
            let parties = ['bank', 'shipper', 'forwarder', 'consignee', 'buyer', 'notifyParty', 'courierParty']
            let result = []
            for (let party of parties) {
                if (party == 'notifyParty') {
                    if (this.item['firstNotifyParty']) {
                        let find = this.allParties.find(x => x.value == party)
                        find.organisation = this.item[party]
                        result.push(find)
                    }
                    if (this.item['secondNotifyParty']) {
                        let find = this.allParties.find(x => x.value == party)
                        find.organisation = this.item[party]
                        result.push(find)
                    }
                }
                else if (this.item[party]) {
                    let find = this.allParties.find(x => x.value == party)
                    find.organisation = this.item[party]
                    if (this.item['onBehalf' + this.$Format.capitalizeFirstLetter(party)]) {
                        find.onBehalfOrganisation = this.item['onBehalf' + this.$Format.capitalizeFirstLetter(party)]
                    }
                    result.push(find)

                }
            }
            return result
        }
    },
    methods: {
    }
}
</script>